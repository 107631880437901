import { useSelector } from '~/app/lib/store/redux';
import { useItemContext } from './ItemPageContext';
import { selectUserCountry } from '~/app/lib/store/session/selectors';

export const PRERELEASE_BLOCKED_COUNTRIES = [
  'HU', // Hungary
  'IN', // India
  'PH', // Philippines
  'TH', // Thailand
  'ZA', // South Africa
  'CN', // China
  'HK', // Hong Kong

  // Benelux
  'BE', // Belgium
  'LU', // Luxembourg
  'NL', // Netherlands

  // Middle east
  'AE', // United Arab Emirates
  'BH', // Bahrain
  'EG', // Egypt
  'IQ', // Iraq
  'IR', // Iran
  'JO', // Jordan
  'KW', // Kuwait
  'LB', // Lebanon
  'OM', // Oman
  'QA', // Qatar
  'SA', // Saudi Arabia
  'SD', // Sudan
  'SS', // South Sudan
  'SY', // Syria
  'YE', // Yemen
];

export const useCountryBlocked = () => {
  const item = useItemContext();
  const country = useSelector(selectUserCountry);

  if (
    item.data.item.type === 'album' &&
    item.data.item.isPrerelease &&
    !item.data.item.isDraft &&
    !item.data.item.userCanEdit
  ) {
    return PRERELEASE_BLOCKED_COUNTRIES.includes(country);
  }

  return false;
};
